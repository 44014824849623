import React, { useState } from 'react';
import { Modal } from 'antd';

import VideoJS from 'components/Video';
import styles from './VideoModal.less';


let videoMap = {
  mv_1:'hptDd2_l81A',
  mv_2:'OWlnC1sNUOY',
  mv_3:'Xmy7bYN3Pb8',
  mv_4:'6OSW-D_JvVQ',
  mv_5:'MtQZd0auwMM',
  mv_6:'5FS2y75laGA',
  mv_7:'r3SKDaYR1ec'
}

export default function VideoModal(props) {

  const [ showImg, setShowImg ] = useState(true)

  console.log(props)

  const {
    visible,
    closeVideoModal,
    title,
    moreVideosOptions,
    afterChangeVideo,
    currentPlayVideoName,
    videoList
  } = props;


  const imgs = videoList.find(items => items.name == currentPlayVideoName) || {}
  

  return (
    <Modal
      visible={visible}
      width={868}
      footer={null}
      title={title}
      onCancel={() => {
        props.closeVideoModal()
        setShowImg(true)
      }}
      className={styles.modalConntainer}
      destroyOnClose={true}
    >
      {/* <VideoJS
        afterChangeVideo={afterChangeVideo}
        moreVideosOptions={moreVideosOptions}
        title = {title}
        currentPlayVideoName={currentPlayVideoName}
        closeMoreVideos={false}
        videoList={videoList}
      /> */}
      <div className={styles.videoBox}>
        {showImg ? <img className={styles.modalConntainerImg} src={imgs.poster} /> : null}
        <iframe className={styles.iframe} width="100%" height="400" src={`https://www.youtube.com/embed/${videoMap[currentPlayVideoName]}?controls=1&loop=1&autoplay=1&playlist=${videoMap[currentPlayVideoName]}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen onLoad={() => { setShowImg(false)  }}></iframe>
      </div>
    </Modal>
  );
}
