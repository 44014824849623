import React from 'react';
import styles from './ImageCard.less';
import { ContentButton, CardTag } from './LightComponents';

const ImageCard = props => {
  const { cardInfo } = props;
  const {
    title,
    desc,
    image,
    video,
    button_name: buttonName,
    button_link: buttonLink,
    tag
  } = cardInfo;
  let videoUrl = video.match(/http[s]{0,1}:\/\/([\w.]+\/?)[^"'\s]*/);
  videoUrl = videoUrl ? videoUrl[0] : '';
  return (
    <div className={styles.imageCardContainer}>
      {/* <div className={styles.tagContainer}>
        {!!tag && <CardTag title={tag} />}
      </div> */}
      <div className={styles.imgArea}>
        {image ? (
          <img src={image} />
        ) : (
          <iframe
            title={title}
            width="100%"
            height="100%"
            frameBorder="0"
            allow="autoplay;encrypted-media"
            allowFullScreen
            src={videoUrl}
          />
        )}
      </div>
      <div className={styles.textArea}>
        <h3 title={title}>{title}</h3>
        <div className={styles.content}>{desc}</div>
        <div className={styles.buttonContainer}>
          <ContentButton buttonInfo={{ title: buttonName, url: buttonLink }} />
        </div>
      </div>
    </div>
  );
};

export default React.memo(ImageCard);
