import React from 'react';
import styles from './LightComponents.less';
import { Button } from 'antd';
import { routerRedux } from 'dva/router';
import { connect } from 'dva';

export const ContentButton = connect()(({ buttonInfo, dispatch }) => {
  const { title, url, actionType } = buttonInfo;
  const clickHandler = () => {
    if (actionType === 'inner') {
      dispatch(routerRedux.push(url));
      return false;
    }
    return null;
    // window.open(url, '_blank');
    // return false;
  };
  return actionType !== 'inner' ? (
    <a style={{ marginRight: 20, color: '#FF8F00' }} href={url} rel="noopener noreferrer" target="_blank">
      {title.toUpperCase()}
    </a>
  ) : (
    <Button className={styles.handleButton} style={{ marginRight: 10 }} onClick={clickHandler}>
      {title}
    </Button>
  );
});

export const CardTag = ({ title }) => {
  return <div className={styles.cardTag}>{title}</div>;
};

export const DismissButton = ({ onClick }) => {
  return (
    <div
      onClick={onClick}
      tabIndex={-1}
      role="link"
      className={styles.dismissButton}
    >
      Dismiss
    </div>
  );
};

export const CardMargin = () => {
  return <div className={styles.cardMaring} />;
}