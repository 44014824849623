import React from 'react';
import { Dropdown, Icon, Menu } from 'antd';
import styles from './CardMoreAction.less';

const CardMoreAction = ({ onSelectAction }) => {
  const menu = (
    <Menu onClick={onSelectAction}>
      <Menu.Item key="dismiss">
        <Icon type="close" />
        Dismiss
      </Menu.Item>
      <Menu.Item key="feedback">
        <Icon type="form" />
        Feedback
      </Menu.Item>
    </Menu>
  );
  return (
    <div className={styles.cardMoreActionContainer}>
      <Dropdown trigger={['click']} overlay={menu} placement="bottomRight">
        <Icon type="more" rotate="90" />
      </Dropdown>
    </div>
  );
};

export default React.memo(CardMoreAction);
