import React from 'react';
import styles from './NormalCard.less';
import { ContentButton } from './LightComponents';

const NormalCard = props => {
  const { cardInfo } = props;
  const { title, buttons = [], imgUrl } = cardInfo;
  return (
    <div className={styles.normalCardContainer}>
      {imgUrl}
      <div className={styles.title}>{title}</div>
      <div className={styles.buttonContainer}>
        {buttons.map((item, index) => {
          return <ContentButton buttonInfo={item} key={index} />;
        })}
      </div>
    </div>
  );
};

export default React.memo(NormalCard);
