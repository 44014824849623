import intl from 'react-intl-universal';

import guide1 from 'assets/home/guide1.png';
import guide2 from 'assets/home/guide2.png';
import guide3 from 'assets/home/guide3.png';
import videoicon from 'assets/home/videoicon.png';
import blogicon from 'assets/home/blogicon.svg';
import moreIcon from 'assets/home/moreIcon.png';

const mv1 =
  'https://img.dsers.com/video/How_to_import_products_in_Find_Supplier.mp4';
const mv2 =
  'https://img.dsers.com/video/How_to_import_products_in_Import_List.mp4';
const mv3 =
  'https://img.dsers.com/video/How_to_send_products_to_Shopify_after_you_imported_them.mp4';
const mv4 =
  'https://img.dsers.com/video/How_to_import_items_from_Shopify_to_DSers.mp4';
const mv5 =
  'https://img.dsers.com/video/How_to_match_the_right_supplier_for_the_product.mp4';
const mv6 = 'https://img.dsers.com/video/How_to_place_an_order_on_DSers.mp4';
const mv7 =
  'https://img.dsers.com/video/How_to_set_up_the_shipping_template.mp4';

const mv_poster_1 =
  'https://img.dsers.com/video/How_to_import_products_in_Find_Supplier.png';
const mv_poster_2 =
  'https://img.dsers.com/video/How_to_import_products_in_Import_List.png';
const mv_poster_3 =
  'https://img.dsers.com/video/How_to_send_products_to_Shopify_after_you_imported_them.png';
const mv_poster_4 =
  'https://img.dsers.com/video/How_to_import_items_from_Shopify_to_DSers.png';
const mv_poster_5 =
  'https://img.dsers.com/video/How_to_match_the_right_supplier_for_the_product.png';
const mv_poster_6 =
  'https://img.dsers.com/video/How_to_place_an_order_on_DSers.png';
const mv_poster_7 =
  'https://img.dsers.com/video/How_to_set_up_the_shipping_template.png';

export const secondaryMenuData = [
  {
    id: '1',
    img: guide1,
    title: intl.get('home.newGuide.second_title_1'),
    descriptions: intl.get('home.newGuide.second_description_1'),
    chlidren: [
      {
        id: '1',
        icon: blogicon,
        title: intl.get('home.newGuide.second_more_1_title_1'),
        type: 'blog',
        name:'blog_1',
        link: 'https://www.dsers.com/blog/what-is-dropshipping/'
      },
      {
        id: '2',
        icon: blogicon,
        title: intl.get('home.newGuide.second_more_1_title_2'),
        type: 'blog',
        name:'blog_2',
        link: 'https://www.dsers.com/blog/start-dropshipping-stores-dsers/'
      },
      {
        id: '3',
        icon: videoicon,
        title: intl.get('home.newGuide.second_more_1_title_3'),
        type: 'video',
        mv: 'mv_1'
      },
      {
        id: '4',
        icon: videoicon,
        title: intl.get('home.newGuide.second_more_1_title_4'),
        type: 'video',
        mv: 'mv_2'
      },
      {
        id: '5',
        icon: videoicon,
        title: intl.get('home.newGuide.second_more_1_title_5'),
        type: 'video',
        mv: 'mv_3'
      }
    ]
  },
  {
    id: '2',
    img: guide2,
    title: intl.get('home.newGuide.second_title_2'),
    descriptions: intl.get('home.newGuide.second_description_2'),
    chlidren: [
      {
        id: '1',
        icon: blogicon,
        title: intl.get('home.newGuide.second_more_2_title_1'),
        type: 'blog',
        name:'blog_3',
        link: 'https://www.dsers.com/blog/evergreen-products-2022/'
      },
      {
        id: '2',
        icon: blogicon,
        title: intl.get('home.newGuide.second_more_2_title_2'),
        type: 'blog',
        name:'blog_4',
        link: 'https://www.dsers.com/blog/trending-dropshipping-products-2022/'
      },
      {
        id: '3',
        icon: videoicon,
        title: intl.get('home.newGuide.second_more_2_title_3'),
        type: 'video',
        mv: 'mv_4'
      },
      {
        id: '4',
        icon: videoicon,
        title: intl.get('home.newGuide.second_more_2_title_4'),
        type: 'video',
        mv: 'mv_5'
      }
    ]
  },
  {
    id: '3',
    img: guide3,
    title: intl.get('home.newGuide.second_title_3'),
    descriptions: intl.get('home.newGuide.second_description_3'),
    chlidren: [
      {
        id: '1',
        icon: blogicon,
        title: intl.get('home.newGuide.second_more_3_title_1'),
        type: 'blog',
        name:'blog_5',
        link: 'https://www.dsers.com/blog/dropshipping-profit-margin/'
      },
      {
        id: '2',
        icon: blogicon,
        title: intl.get('home.newGuide.second_more_3_title_2'),
        type: 'blog',
        name:'blog_6',
        link: 'https://www.dsers.com/blog/deal-with-first-order/'
      },
      {
        id: '3',
        icon: videoicon,
        title: intl.get('home.newGuide.second_more_3_title_3'),
        type: 'video',
        mv: 'mv_6'
      },
      {
        id: '4',
        icon: videoicon,
        title: intl.get('home.newGuide.second_more_3_title_4'),
        type: 'video',
        mv: 'mv_7'
      }
    ]
  }
];

export const videos = [
  {
    id: '1',
    name: 'mv_1',
    src: mv1,
    poster: mv_poster_1,
    title: intl.get('home.newGuide.second_more_1_title_3')
  },
  {
    id: '2',
    name: 'mv_2',
    src: mv2,
    poster: mv_poster_2,
    title: intl.get('home.newGuide.second_more_1_title_4')
  },
  {
    id: '3',
    name: 'mv_3',
    src: mv3,
    poster: mv_poster_3,
    title: intl.get('home.newGuide.second_more_1_title_5')
  },
  {
    id: '4',
    name: 'mv_4',
    src: mv4,
    poster: mv_poster_4,
    title: intl.get('home.newGuide.second_more_2_title_3')
  },
  {
    id: '5',
    name: 'mv_5',
    src: mv5,
    poster: mv_poster_5,
    title: intl.get('home.newGuide.second_more_2_title_4')
  },
  {
    id: '6',
    name: 'mv_6',
    src: mv6,
    poster: mv_poster_6,
    title: intl.get('home.newGuide.second_more_3_title_3')
  },
  {
    id: '7',
    name: 'mv_7',
    src: mv7,
    poster: mv_poster_7,
    title: intl.get('home.newGuide.second_more_3_title_4')
  }
];
