import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'dva';
import intl from 'react-intl-universal';

import styles from './TextScroll.less';

export default function TextScroll() {
  const scroll_begin = useRef(null);
  const scroll_div = useRef(null);
  const scroll_end = useRef(null);
  let MyMar = null;

  useEffect(() => {
    ScrollImgLeft();
    return () => {
      clearInterval(MyMar);
    };
  }, []);
  const ScrollImgLeft = () => {
    var speed = 30;
    scroll_end.current.innerHTML = scroll_begin.current.innerHTML;
    const Marquee = () => {
      if (
        scroll_end?.current?.offsetWidth - scroll_div.current?.scrollLeft <=
        0
      )
        scroll_div.current.scrollLeft -= scroll_begin?.current?.offsetWidth;
      else scroll_div.current.scrollLeft++;
    };
    MyMar = setInterval(Marquee, speed);
  };
  return (
    <div className={styles.scrollCon} ref={scroll_div}>
      <div className={styles.textCon} ref={scroll_begin}>
        <span className={styles.pad_right}>
          {intl.getHTML('home.guidance.tip_to_pc')}
        </span>
      </div>
      <div className={styles.textCon} ref={scroll_end}></div>
    </div>
  );
}
