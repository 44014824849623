import React from 'react';
import styles from './NotificationCard.less';
import { ContentButton, DismissButton } from './LightComponents';
import { Icon } from 'antd';

const NotificationCard = props => {
  const { cardInfo } = props;
  const { title, buttons = [], imgUrl, content } = cardInfo;
  return (
    <div className={styles.notificationContainer}>
      <div className={styles.title}>
        <Icon type="warning" />
        &nbsp;&nbsp;Notification
      </div>
      <div className={styles.content}>{content}</div>
      <div className={styles.buttonContainer}>
        <DismissButton />
        {buttons.map((item, index) => {
          return <ContentButton buttonInfo={item} key={index} />;
        })}
      </div>
    </div>
  );
};

export default React.memo(NotificationCard);
