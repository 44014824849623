import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { connect, useDispatch } from 'dva';
import intl from 'react-intl-universal';
import {
  Spin,
  Button,
  Progress,
  Popover,
  Tooltip,
  message,
  Dropdown,
  Menu,
  Icon,
  Modal
} from 'antd';
import { captureMessage, init } from '@sentry/react';
import { ChromeAction } from 'actions';

import HomeCard from './components/HomeCard';
import styles from './index.less';
import FeedbackModal from './components/FeedbackModal';
import { CardMargin } from './components/LightComponents';
import setUpIcon from '../../assets/home/home_info.png';
import reportIcon from '../../assets/home/home_report.png';
import iconCampaign from 'assets/mobile/icon_campaign.png';
import moreVert from 'assets/mobile/more_vert.png';
import successimg from '../../assets/home/success.png';
import unsuccessimg from '../../assets/home/unsuccess.png';
import errorimg from '../../assets/home/iconerror.png';
import syncimg from '../../assets/home/syncimg.png';
import success_img from '../../assets/home/successimg.png';
import stopimg from '../../assets/home/stopimg.png';
import close_migrate_img from '../../assets/home/close_migrate_img.png';
import pendingimg from '../../assets/home/pendingimg.png';
import syncproducts from 'assets/home/syncorder.png';
import successicon from 'assets/successicon.png';
import AdBanner from 'components/AdBanner';
import NoviceGuidance from './components/NoviceGuidance';
import TextScroll from './components/TextScroll';
import HomeGuide from './components/HomeGuide';

import { getHomepageCards } from '../../services/home';
import { getUserPlatform } from '../../utils/utils';
import { hasBeginnerGuideAccess } from '../BeginnerGuide/util';

import { Get, Post } from 'utils/request';

import {
  oberloReport,
  getOberloReport,
  getChromeExtUrl,
  CommonDataCollect
} from 'utils/utils';
const chromeExtLink =
  'https://chrome.google.com/webstore/detail/dsers-aliexpresscom-produ/mmanaflgaempokjfbeeabkadnkoidjam?hl=';
const getNormalCards = () => [
  {
    title: intl.get('home.setup_account'),
    imgUrl: <i class="material-icons notranslate">account_circle</i>,
    buttons: [
      {
        title: intl.get('home.tutorial'),
        url: hasBeginnerGuideAccess() ? `/app/tutorials_video` : `/app/guidance`
      },
      {
        title: intl.get('home.chrome_extension_title'),
        url: `${chromeExtLink}${intl.getInitOptions().currentLocale}`
      },
      // {
      //   title: intl.get('home.learn_more'),
      //   url: intl.get('home.home_normal_card_learn_more_link')
      // }
      {
        title: 'HELP CENTER',
        url: 'https://help.dsers.com/'
      }
    ]
  },
  // {
  //   title: intl.get('home.check_report'),
  //   imgUrl: <i class="material-icons notranslate">insert_chart_outlined</i>,
  //   buttons: [
  //     {
  //       title: intl.get('home.report'),
  //       url: `/app/report`
  //     }
  //   ]
  // }
];
// 暂时不上线notice类型的卡片
const notificationInfo = {
  title: 'Check your sales, cost and product ROI',
  imgUrl: reportIcon,
  content:
    'Due to new regulations, all orders to Brazil must contain the CPF number, or the orders will fail. DSers will make the CPF number appear on your packages. You can add the CPF number of your customer in the "Company" field, in Customer Details.',
  buttons: [
    {
      title: 'Learn More',
      url: `/app/home`
    }
  ]
};

// 倒序排列 找出最新的卡片数据
const setLatestHomeMessage = cards => {
  const sortedCards = [...cards].sort((item1, item2) => {
    const time1 = +new Date(item1.update_at);
    const time2 = +new Date(item2.update_at);
    return time2 - time1;
  });
  if (sortedCards[0]) {
    localStorage.setItem('latestHomeMessage', JSON.stringify(sortedCards[0]));
  }
};

const Home = ({
  userInfo,
  isChildAcount,
  dispatch,
  lang,
  isBindAe,
  isInstallChromePlug,
  plan_info,
  isoberlouser,
  OstoreInfo,
  dsers_oberlo_plan,
  migrationInfo
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [modalInfo, setModalInfo] = useState({});
  const normalCards = useMemo(getNormalCards, []);
  const [imageCards, setImageCards] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadings, setLoadings] = useState(true);
  //oberlo迁移部分开始
  const [oberloToDsersShop, setOberloToDsersShop] = useState(false); // 判断是不是有过迁移的店铺
  const [percent, setPercent] = useState(0); // 完成百分比
  const [step, setStep] = useState(1); // 还有几步没有完成
  const [plan, setPlan] = useState('Boss'); // oberlo的套餐
  const [aliexpress, setAliexpress] = useState(true); // 是否绑定AE
  const [plantype, setPlantype] = useState(true); // 是否需要确认套餐
  const [isClickAe, setisClickAe] = useState(true); // 是否绑定过AE
  const [plugin, setPlugin] = useState(true); // 是否安装了插件
  const [billing, setBilling] = useState(true); // billing是否异常
  const [yearerror, setYearerror] = useState(false); // 是否提示年费用户信息
  const [isFree, setIsFree] = useState([]); // oberlo套餐是不是免费，免费则不需要显示企鹅人套餐按钮
  const [showOldHome, setShowOldHome] = useState(true); // 是否展示老home页
  const [closeMigrate, setCloseMigrate] = useState(false); //是否展示关闭新home按钮
  const [nowTime, setNowTime] = useState(parseInt(new Date().getTime() / 1000));
  const [overTime, setOverTime] = useState(Number(OstoreInfo?.last_pre_complete_timestamp) +300);
  const [migrateProductOver, setMigrateProductOver] = useState(false);
  const [migrateOrdertOver, setMigrateOrderOver] = useState(false);
  const [hasPlug, setHasPlug] = useState(false);
  const [shopname, setShopname] = useState(
    userInfo.stores.filter(i => {
      return i.id == plan_info.default_store_id;
    })[0]?.shop_name
  ); // 获取plan中的默认billing账单的店铺名

  const planType = {
    Boss: intl.get('pricing.Advanced'),
    Explorer: intl.get('pricing.custom_basic'),
    Pro: intl.get('pricing.Pro')
  };

  const pricingType = {
    1: 10,
    2: 2,
    3: 3
  };
  const getTextHandler = () => {
    if (billing) {
      if (plantype) {
        return intl.get('home.oberlo.finish');
      } else {
        return intl.get('home.oberlo.btntext2');
      }
    } else {
      if (dsers_oberlo_plan?.compare) {
        return intl.get('home.oberlo.btntext2');
      } else {
        return intl.get('home.oberlo.finish');
      }
    }
  };

  let aeSpecial;
  let interval = null;

  const getMigrateStepImg = option => {
    if (OstoreInfo?.store_stages?.length) {
      aeSpecial = OstoreInfo?.store_stages[
        OstoreInfo?.store_stages?.length - 1
      ]?.tips?.includes('TIP_REASON_ALIEXPRESS_UNAUTHORIZED');
    }
    switch (option) {
      case 1:
        if (!migrateProductOver) {
          return [pendingimg, intl.get('home.oberlo.migrated')];
        } else {
          return [successimg, intl.get('home.oberlo.finish')];
        }
      case 2:
        if (
          (aliexpress || isClickAe || aeSpecial) &&
          !migrateOrdertOver
        ) {
          return [pendingimg, intl.get('home.oberlo.linked')];
        } else if (
          (aliexpress || isClickAe || aeSpecial) &&
          migrateOrdertOver
        ) {
          return [successimg, intl.get('home.oberlo.finish')];
        } else {
          return [unsuccessimg, intl.get('home.oberlo.btntext1')];
        }
      case 3:
        if (billing) {
          if (plantype) {
            return [successimg, intl.get('home.oberlo.finish')];
          } else {
            return [unsuccessimg, intl.get('home.oberlo.btntext2')];
          }
        } else {
          if (dsers_oberlo_plan?.compare) {
            return [unsuccessimg, intl.get('home.oberlo.btntext2')];
          } else {
            return [successimg, intl.get('home.oberlo.finish')];
          }
        }
      case 4:
        if (plugin || hasPlug) {
          return [successimg, intl.get('home.oberlo.finish')];
        } else {
          return [unsuccessimg, intl.get('home.oberlo.btntext4')];
        }
    }
  };

  const welcomeOberlo = [
    {
      id: '1',
      img: getMigrateStepImg(1)[0],
      title: intl.getHTML('home.oberlo.step_title_4'),
      describe: intl.getHTML('home.oberlo.step_des_4'),
      btntext: getMigrateStepImg(1)[1],
      procress: migrateProductOver,
      disabled: getMigrateStepImg(1)[0] != unsuccessimg,
      success: getMigrateStepImg(1)[0] == successimg
    },
    {
      id: '2',
      img: getMigrateStepImg(2)[0],
      title: intl.getHTML('home.oberlo.step_title_1'),
      describe: intl.getHTML('home.oberlo.step_des_1'),
      btntext: getMigrateStepImg(2)[1],
      procress: migrateOrdertOver,
      disabled: getMigrateStepImg(2)[0] != unsuccessimg,
      success: getMigrateStepImg(2)[0] == successimg
    },
    {
      id: '3',
      img: getMigrateStepImg(3)[0],
      title: intl.getHTML('home.oberlo.step_title_2'),
      describe: intl.getHTML('home.oberlo.step_des_2'),
      obPlan: intl.getHTML('home.oberlo.obPlan', {
        dsers: planType[plan],
        oberlo: plan
      }),
      btntext: getTextHandler(),
      current: intl.getHTML('home.oberlo.btntext3'),
      disabled: getMigrateStepImg(3)[0] != unsuccessimg || plantype,
      error: true
    },
    {
      id: '4',
      img: getMigrateStepImg(4)[0],
      title: intl.getHTML('home.oberlo.step_title_3'),
      describe: intl.getHTML('home.oberlo.step_des_3'),
      btntext: getMigrateStepImg(4)[1],
      disabled: getMigrateStepImg(4)[0] != unsuccessimg
    }
  ];
  const newWelcomeOberlo = [
    {
      id: '1',
      img: getMigrateStepImg(1)[0],
      title: intl.getHTML('home.oberlo.step_title_4'),
      describe: intl.getHTML('home.oberlo.step_des_4'),
      btntext: getMigrateStepImg(1)[1],
      procress: migrateProductOver,
      disabled: getMigrateStepImg(1)[0] != unsuccessimg,
      success: getMigrateStepImg(1)[0] == successimg
    },
    {
      id: '2',
      img: getMigrateStepImg(2)[0],
      title: intl.getHTML('home.oberlo.step_title_1'),
      describe: intl.getHTML('home.oberlo.step_des_1'),
      btntext: getMigrateStepImg(2)[1],
      procress: migrateOrdertOver,
      disabled: getMigrateStepImg(2)[0] != unsuccessimg,
      success: getMigrateStepImg(2)[0] == successimg
    },
    {
      id: '4',
      img: getMigrateStepImg(4)[0],
      title: intl.getHTML('home.oberlo.step_title_3'),
      describe: intl.getHTML('home.oberlo.step_des_3'),
      btntext: getMigrateStepImg(4)[1],
      disabled: getMigrateStepImg(4)[0] != unsuccessimg
    }
  ];

  const getPricing = () => {
    return pricingType[dsers_oberlo_plan?.oberlo];
  };

  const getYearErr = () => {
    if (
      (plan_info.type == 4 || plan_info.type == 8) &&
      !!dsers_oberlo_plan?.compare
    ) {
      setYearerror(true);
    } else {
      setYearerror(false);
    }
  };

  // && (plan_info.type == 1 || plan_info.type == 10)
  const getBillingStatus = () => {
    const default_store_id = plan_info?.default_store_id;
    const planInfo = JSON.parse(localStorage.getItem('payment'));
    if (planInfo?.default_store_id) {
      dispatch({
        type: 'dropshippersetting/shopifyStatus',
        payload: {
          data: {
            store_id: planInfo.default_store_id
          },
          cancelMessage: true,
          callback: d => {
            if (d?.data?.is_available) {
              if (dsers_oberlo_plan?.compare) {
                setPlantype(false);
                setBilling(true);
              } else {
                setBilling(true);
                setPlantype(true);
              }
            } else {
              if (dsers_oberlo_plan?.compare) {
                setPlantype(true);
                setBilling(false);
              } else {
                if (planInfo.type == 1 || planInfo.type == 10) {
                  setBilling(true);
                } else {
                  setBilling(false);
                  setPlantype(true);
                }
              }
            }
          }
        }
      });
    } else {
      if (dsers_oberlo_plan?.compare) {
        setPlantype(true);
        setBilling(false);
      } else {
        if (planInfo?.type == 1 || planInfo?.type == 10) {
          setBilling(true);
        } else {
          setBilling(false);
          setPlantype(true);
        }
      }
    }
  };

  const getMigraTionProcess = () => {
    // console.log(overTime - nowTime,nowTime, 'nowTime nowTime nowTime nowTime nowTime home');
    if (overTime - nowTime > -1) {
      interval = setInterval(() => {
        clearInterval(interval)
        setNowTime(nowTime + 1);
        if (overTime - nowTime <= 0) {
          setMigrateProductOver(true);
        } else  {
          setMigrateProductOver(false);
        }
      }, 1000);
    }else{
      setMigrateProductOver(true);
    }
  };
  useEffect(()=>{
    const byId=localStorage.getItem('key1')
    const byClass=localStorage.getItem('key2')
    byId && byId?.style?(
      byId.style.marginTop='112px'
    ):null
    // 左侧
    byClass && byClass?.style?(
      byClass.style.marginTop='48px',
      byClass.style.height='calc(100% - 64px - 48px)'
    ):null
  },[])
  useEffect(() => {
    getOberloReport('ae_plan_plug_status').then(res => {
      if (res?.value == 'true') {
        setShowOldHome(true);
      } else {
        setShowOldHome(false);
      }
      setLoadings(false);
    });
    if (oberloToDsersShop) {
      getOberloReport('out_bind_ae').then(res => {
        if (res?.value == 'true') {
          setisClickAe(true);
        } else {
          setisClickAe(false);
        }
      });
      getOberloReport('has_Plug').then(res => {
        if (res?.value == 'true') {
          setHasPlug(true);
        } else {
          setHasPlug(false);
        }
      });
    }
    dispatch({ type: 'user/getProject' });
    CommonDataCollect({
      action: 'ae_plan_plug_status',
      scene: 1,
      event_label: 'status',
      aliexpress_status: !!isBindAe ? 1 : 2,
      plan_status: !dsers_oberlo_plan.compare ? 1 : 2,
      extension_status: !!isInstallChromePlug ? 1 : 2
    });

  }, [oberloToDsersShop]);

  useEffect(() => {
    getBillingStatus();
    if(isChildAcount === 2){
      setIsFree(newWelcomeOberlo)
    }else if(dsers_oberlo_plan?.flag  && !(isChildAcount === 2)){
      setIsFree(welcomeOberlo)
    }else if(!dsers_oberlo_plan?.flag  && !(isChildAcount === 2)){
      setIsFree(newWelcomeOberlo)
    }else{
      setIsFree(welcomeOberlo)
    }
    console.log(getMigrateStepImg(3)[0],'getMigrateStepImg(3)[0]getMigrateStepImg(3)[0]');
  }, [
    plan_info.default_store_id,
    plugin,
    hasPlug,
    aeSpecial,
    aliexpress,
    billing,
    plantype,
    isClickAe,
    migrateProductOver,
    migrationInfo
  ]);
  
  useEffect(()=>{
    getMigraTionProcess();



    return function() {
      clearInterval(interval);
    };
  },[migrationInfo,nowTime])

  useEffect(() => {
    // oberloReport('has_Plug','')
    setOberloToDsersShop(isoberlouser);
    setMigrateOrderOver(migrationInfo?.is_step2_order_completed)
    setAliexpress(!!isBindAe);
    setPlugin(!!isInstallChromePlug);

    if (dsers_oberlo_plan?.oberlo == 1) {
      if (step == 1) {
        setPercent(66.66);
      } else if (step == 2) {
        setPercent(33.33);
      } else if (step == 3) {
        setPercent(0);
      } else if (step == 0) {
        setPercent(100);
      }
    } else {
      if (step == 1) {
        setPercent(75);
      } else if (step == 2) {
        setPercent(50);
      } else if (step == 3) {
        setPercent(25);
      } else if (step == 4) {
        setPercent(0);
      } else if (step == 0) {
        setPercent(100);
      }
    }

    if (dsers_oberlo_plan?.oberlo == 1) {
      setPlan('Explorer');
    } else if (dsers_oberlo_plan?.oberlo == 2) {
      setPlan('Boss');
    } else if (dsers_oberlo_plan?.oberlo == 3) {
      setPlan('Pro');
    }

    const stepnumber = isFree.filter(i => {
      return i.img == unsuccessimg;
    }).length;
    setStep(stepnumber);

    getYearErr();
  }, [welcomeOberlo, newWelcomeOberlo]);

  useEffect(() => {
    if (
      hasPlug &&
      !dsers_oberlo_plan?.compare &&
      migrateProductOver &&
      migrateOrdertOver
    ) {
      setCloseMigrate(true);
    } else {
      setCloseMigrate(false);
    }
  }, [hasPlug, OstoreInfo, dsers_oberlo_plan]);

  // 获取是否需要确认套餐信息
  const setPlanDisHandler = () => {
    dispatch({
      type: 'topBtn/checkPlan',
      payload: {
        data: {
          manual: 1
        },
        callback: () => {
          setPlantype(true);
        }
      }
    });
  };

  const closeMigrateHome = () => {
    oberloReport('ae_plan_plug_status', 'true').then(() => {
      setShowOldHome(true);
    });
  };

  // 获取展示每步进度样式
  const getStyles = (item, type, pro) => {
    if (type == 'class') {
      // return styles.stopProcress;
      if (migrationInfo.status == 'MIGRATION_STATUS_STOP' && item.id == 2) {
          return styles.stopProcress;
      } else {
        return item.success ? styles.successProcress : styles.procress;
      }
    } else if (type == 'img') {
      // return stopimg;
      if (migrationInfo.status == 'MIGRATION_STATUS_STOP' && item.id == 2) {
        return stopimg;
      } else {
        return item.success ? success_img : syncimg;
      }
    } else if (type == 'text') {
      // return intl.getHTML('home.oberlo.stopProduct');
      if (migrationInfo.status == 'MIGRATION_STATUS_STOP' && item.id == 2) {
        return intl.getHTML('home.oberlo.stopOrder');
      } else {
        return item.success
          ? intl.getHTML('home.oberlo.successOrder')
          : intl.getHTML('home.oberlo.syncOrder')
      }
    }else if(type == 'product'){
      return item.success
        ? intl.getHTML('home.oberlo.successProduct')
        : intl.getHTML('home.oberlo.syncProduct')
    }
  };

  const tryAgainHandler = async () => {
    const res = await Get(
      '/mgnoberlo-core-api/dsers-api/migration/user/status',
      [],
      true
    );
    if (res?.err_code == 'SHOPIFY_UNAUTHORIZED') {
      const response = await Post(
        '/mgnoberlo-core-api/dsers-api/migration/auth/shopify/bind',
        {
          data: {
            shop_name: res?.migrating_shopify_domain.split('.myshopify.com')[0],
            store_id: res?.dsers_store_id
          }
        },
        true
      );
      window.location.href = response.redirect_url;
    } else {
      if(res.migrating_shopify_domain){
        await Post(
          '/mgnoberlo-core-api/dsers-api/migration/start',
          {
            data: {
              shopify_domain: res.migrating_shopify_domain
            }
          },
          true
        );
        dispatch({ type: 'auth/migrationInfo' });
      }
    }
  };

  const toVideoHandler = () => {
    window.open('/app/tutorials_video');
  };

  const logException = (res, identify) => {
    if (res?.msg) {
      captureMessage(`[pricing] ${res.msg}`);
    } else {
      captureMessage(`[pricing] ${identify}`);
    }
  };

  const toFunHandler = type => {
    return () => {
      if (type == 2) {
        CommonDataCollect({
          action: 'migration_bind_ae',
          scene: 1
        });
        window.dataLayer.push({
          event: 'bindAEEvent',
          type: 'linkToAE',
          position: 'bindAE'
        });

        dispatch({
          type: 'topBtn/aliauth',
          payload: {
            cancelMessage: true,
            callback: d => {
              if (d.code === 2000) {
                window.location = d.data.url;
              } else if (d.code === 4090) {
                window.location = '/app/';
              } else {
                message.error(d.msg);
              }
            }
          }
        });
      } else if (type == 3) {
        CommonDataCollect({
          action: 'migration_choose_plan',
          scene: 1
        });
        dispatch({
          type: 'guidance/modificationProject',
          payload: {
            data: {
              plan_type: getPricing(),
              plan_period: 1
            },
            callback: d => {
              if (d.code == 2000) {
                if (d.data.confirm_url) {
                  window.location.href = d.data.confirm_url;
                  return;
                }
              } else {
                logException(d, 'guidance/modificationProject');
              }
            }
          }
        });
      } else if (type == 4) {
        window.localStorage.setItem('MIGRATION_PLUG_STATUS', true);
        if (!window.CHROME_PLUG_INSTALL) {
          //migration_install_chrome
          CommonDataCollect({
            action: 'migration_install_chrome',
            scene: 1
          });

          window.open(getChromeExtUrl(), '_blank');
          window.EVENT.emit(ChromeAction.NO_INSTALL_MODAL_RELOAD, true);
          return;
        }
      }
    };
  };

  // 去shopify解决billing异常
  const toShopifyBillingHandler = e => {
    e.preventDefault();
    dispatch({
      type: 'auth/createShopfiy',
      payload: {
        data: {
          shop_name: shopname
        },
        callback: d => {
          if (d.code === 2000 || d.code === 2010) {
            if (d.msg === 'bind store quota err') {
              localStorage.setItem('passivity', 'true');
              self.location.href = '/app/pricing';
            } else {
              window.location.href = d?.data?.url || '/';
            }
          } else {
          }
        }
      }
    });
  };

  //oberlo迁移部分结束

  const onDismiss = useCallback(cardInfo => {
    return 0;
  }, []);

  const menu = (
    <Menu className={styles.home_menu} selectedKeys={[]}>
      <Menu.Item className={styles.item} key="Facebook">
        <a target="_blank" href="/app/tutorials_video">
          {' '}
          {intl.get('home.tutorial')}
        </a>
      </Menu.Item>
      <Menu.Item className={styles.item} key="Youtube">
        <a
          target="_blank"
          href={`${chromeExtLink}${intl.getInitOptions().currentLocale}`}
        >
          {intl.get('home.chrome_extension_title')}
        </a>
      </Menu.Item>
      <Menu.Item className={styles.item}>
        <a href={'https://help.dsers.com/'} target="_blank">
          <span>HELP CENTER</span>
        </a>
      </Menu.Item>
    </Menu>
  );

  const onOpenFeedbackModal = useCallback(cardInfo => {
    setModalInfo(cardInfo);
    setModalVisible(true);
  }, []);

  // 点击卡片more action
  const onSelectAction = useCallback(
    (e, cardInfo) => {
      if (e.key === 'dismiss') {
        onDismiss(cardInfo);
      } else {
        onOpenFeedbackModal(cardInfo);
      }
    },
    [onDismiss, onOpenFeedbackModal]
  );

  const closeFeedbackModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  const submitFeedback = useCallback(values => {
    console.log(values);
    setModalVisible(false);
  }, []);

  // 获取卡片数据
  useEffect(() => {
    const { status, stores } = userInfo;
    const platform = getUserPlatform(status, stores);
    (async () => {
      try {
        const res = await getHomepageCards({ platform });
        if (res.code === 2000) {
          let cards = res.data || [];
          if (new Date().getMonth() % 2 == 0) {
            cards = cards.reverse();
          }
          setImageCards(cards);
          setLatestHomeMessage(cards);
          dispatch({
            type: 'global/setHomeUnreadMessage',
            payload: false
          });
        }
      } catch (e) {
        console.log(e);
      }
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    window.dataLayer.push({
      event: 'homepageEvent',
      type: 'pageLoaded'
    });
  }, []);

  return (
    <>
    {loadings ? (
        <Spin
          size="large"
          style={{ width: '100%', margin: '180px auto' }}
        />
      ) : !oberloToDsersShop || showOldHome ?
        <>
        {IS_MOBILE ? (
          <div className={styles.mhomeContainer}>
            <div className={styles.tips}>
              <img src={iconCampaign} alt="" />
              <div className={styles.tip}>
                <TextScroll></TextScroll>
              </div>
            </div>
            <h3 className={styles.title}>{intl.get('home.home_title')}</h3>
            <div className={styles.homeCardWrapper} id="home_menu1">
              <div className={styles.accountCon}>
                <div className={styles.funtitle}>Set up your DSers account</div>
                <Dropdown 
                  overlay={menu}
                  getPopupContainer={() => document.getElementById('home_menu1')}
                  >
                  <img src={moreVert} alt="" />
                </Dropdown>
              </div>
            </div>
            <div
              className={styles.guidanceWrapper}
              style={{padding:'16px'}}
            >
              <HomeGuide></HomeGuide>
            </div>
            {loading ? (
              <Spin
                size="large"
                style={{ width: '100%', margin: '180px auto' }}
              />
            ) : null}
            <div className={styles.cardsCon}>
            {imageCards.map((item, index) => (
              <React.Fragment key={item.id}>
                <CardMargin />
                <div className={styles.cardCon}>
                  <div className={styles.top}>
                    <img src={item.image} alt="" />
                  </div>
                  <div className={styles.bottom}>
                    <div className={styles.marginCon}>
                      <p className={styles.title1}>{item.title}</p>
                      <p className={styles.desc}>{item.desc}</p>
                      <p className={styles.link}>
                        <a href={item.button_link}>{item.button_name}</a>
                      </p>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ))}
            </div>
            <div className={styles.bottomLine}>
              <p>{intl.get('home.bottom_line')}</p>
            </div>
            <FeedbackModal
              closeModal={closeFeedbackModal}
              submitFeedback={submitFeedback}
              visible={modalVisible}
              modalInfo={modalInfo}
            />
          </div>
        ) : (
          <div className={styles.homeContainer}>
            <div className={styles.banner}>
              {/* <HappyNewYear></HappyNewYear> */}
            </div>
            <h3 className={styles.title}>{intl.get('home.home_title')}</h3>
            <div className={styles.homeCardWrapper}>
              {normalCards.map((item, index) => (
                <HomeCard cardInfo={item} showMoreAction={false} key={index} />
              ))}
            </div>
            <div
              className={styles.guidanceWrapper}
            >
              <HomeGuide></HomeGuide>
            </div>
            {loading ? (
              <Spin
                size="large"
                style={{ width: '100%', margin: '180px auto' }}
              />
            ) : null}
            <div className={styles.guidanceWrapper}>
              {imageCards.map((item, index) => (
                <React.Fragment key={item.id}>
                  <CardMargin />
                  <HomeCard
                    onSelectAction={onSelectAction}
                    cardType="imgCard"
                    cardInfo={item}
                    showMoreAction={false}
                  />
                </React.Fragment>
              ))}
            </div>
            <div className={styles.bottomLine}>
              <p>{intl.get('home.bottom_line')}</p>
            </div>
            <FeedbackModal
              closeModal={closeFeedbackModal}
              submitFeedback={submitFeedback}
              visible={modalVisible}
              modalInfo={modalInfo}
            />
          </div>
        )}
      </>
      : (
        <div className={styles.fromOberlocontainer}>
          
          <div className={styles.welcomewrapper}>
            <div className={styles.titleContainer}>
              <h4>{intl.getHTML('home.oberlo.card_title_1')}</h4>
              <p>{intl.getHTML('home.oberlo.card_title_des_1')}</p>
              {closeMigrate ? (
                <img
                  className={styles.close_migrate}
                  onClick={closeMigrateHome}
                  src={close_migrate_img}
                  alt=""
                />
              ) : null}
            </div>
            <div className={styles.funcontainer}>
              <div className={styles.leftCon}>
                <div className={styles.steps}>
                  <div className={styles.procon}>
                    <Progress
                      type="circle"
                      percent={percent}
                      showInfo={false}
                      strokeColor="#4CAF50"
                      trailColor="#9FA8DA"
                      strokeLinecap="square"
                      strokeWidth={10}
                      width={96}
                    />
                    <span className={styles.process}>
                      {isFree?.length === newWelcomeOberlo?.length ? (
                        <>{`${Math.abs(step - 3)}`}/3</>
                      ) : (
                        <>{`${Math.abs(step - 4)}`}/4</>
                      )}
                    </span>
                  </div>
                  <span className={styles.steptxet}>
                    {step} {intl.getHTML('home.oberlo.stepsdes')}
                  </span>
                </div>
              </div>
              <div className={styles.rightCon}>
                <div className={styles.functionWrapper}>
                  {isFree.map(i => (
                    <div className={styles.desCon}>
                      <div className={styles.desCon1}>
                        <div className={styles.desConWrapper}>
                          <img src={i.img} className={styles.stepImg} alt="" />
                          <div className={styles.text}>
                            <span className={styles.title}>{i.title}</span>
                            <span className={styles.des}>{i.describe}</span>
                            {i.obPlan && !i.disabled ? (
                              <>
                                <span className={styles.des}>{i.obPlan}</span>
                              </>
                            ) : null}
                          </div>
                        </div>
                        <div className={styles.btnCon}>
                          {yearerror && i.id == 3 ? (
                            <Tooltip
                              placement="top"
                              title={intl.getHTML('home.oberlo.year_error')}
                            >
                              <Button
                                disabled={i.disabled}
                                onClick={toFunHandler(i.id)}
                              >
                                {i.btntext}
                              </Button>
                            </Tooltip>
                          ) : (
                            <Button
                              disabled={i.disabled}
                              onClick={toFunHandler(i.id)}
                            >
                              {i.btntext}
                            </Button>
                          )}
                          {!i.disabled && i.current ? (
                            <Button
                              className={styles.special}
                              onClick={setPlanDisHandler}
                            >
                              {i.current}
                            </Button>
                          ) : null}
                        </div>
                      </div>

                      {i.id == 1 ? (
                        <div className={getStyles(i, 'class')}>
                          <div className={styles.leftTipCon}>
                            <img
                              src={getStyles(i, 'img')}
                              className={styles.loadings}
                              alt=""
                            />
                            <span>{getStyles(i, 'product')}</span>
                          </div>
                          {getStyles(i, 'img') == stopimg ? (
                            <span
                              className={styles.tryAgain}
                              onClick={tryAgainHandler}
                            >
                              {intl.getHTML('public.oberlo_data_try')}
                            </span>
                          ) : null}
                        </div>
                      ) : null}

                      {i.id == 2 && i.disabled ? (
                        <div className={getStyles(i, 'class')}>
                          <div className={styles.leftTipCon}>
                            <img
                              src={getStyles(i, 'img')}
                              className={styles.loadings}
                              alt=""
                            />
                            <span>{getStyles(i, 'text')}</span>
                          </div>
                          {getStyles(i, 'img') == stopimg ? (
                            <span
                              className={styles.tryAgain}
                              onClick={tryAgainHandler}
                            >
                              {intl.getHTML('public.oberlo_data_try')}
                            </span>
                          ) : null}
                        </div>
                      ) : null}

                      {i.error && !billing ? (
                        <div className={styles.billing}>
                          <img src={errorimg} className={styles.img} alt="" />
                          <span className={styles.des}>
                            {plan_info?.default_store_id && shopname ? (
                              <>
                                {intl.getHTML('home.oberlo.billing_error')}{' '}
                                <a onClick={toShopifyBillingHandler}>
                                  {intl.getHTML(
                                    'home.oberlo.billing_error_link'
                                  )}
                                </a>
                              </>
                            ) : (
                              <>{intl.getHTML('home.oberlo.billing_error1')}</>
                            )}
                          </span>
                        </div>
                      ) : null}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className={styles.tutorial}>
            <div className={styles.leftCon}>
              <h4>{intl.getHTML('home.oberlo.card_title_2')}</h4>
              <p>{intl.getHTML('home.oberlo.card_title_des_2')}</p>
            </div>
            <div>
              <Button onClick={toVideoHandler}>
                {intl.getHTML('home.oberlo.btntext5')}
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default connect(({ login, global, user, auth, topBtn }) => ({
  userInfo: login.userInfo,
  isChildAcount: user.newUserInfo.type, //判断是否是子账户
  lang: global.lang,
  isBindAe: global.isBindAe,
  isInstallChromePlug: global.isInstallChromePlug,
  plan_info: user.plan,
  isoberlouser: auth.isOberloUser,
  OstoreInfo: auth.OstoreInfo,
  dsers_oberlo_plan: topBtn.fromOberloPlan,
  migrationInfo: auth?.migrationInfo
}))(React.memo(Home));
