import React from 'react';
import { Modal, Form, Radio, Input } from 'antd';
import intl from 'react-intl-universal';

const { TextArea } = Input;
const FeedbackModal = props => {
  const { form, visible, closeModal, submitFeedback } = props;
  const { getFieldDecorator, getFieldValue } = form;
  const commnets = getFieldValue('comments');
  const isUseful = getFieldValue('isUseful');
  return (
    <Modal
      destroyOnClose
      visible={visible}
      onCancel={closeModal}
      onOk={() => submitFeedback({ commnets, isUseful })}
      okText={intl.get('home.share_feedback')}
      okButtonProps={{ disabled: !(commnets || isUseful) }}
      closable={false}
    >
      <h3>{intl.get('home.feedback_share')}</h3>
      <Form.Item>
        {getFieldDecorator('isUseful', {
          initialValue: ''
        })(
          <Radio.Group>
            <Radio value="useful">{intl.get('home.feedback_useful')}</Radio>
            <br />
            <Radio value="not_useful">
              {intl.get('home.feedback_not_useful')}
            </Radio>
          </Radio.Group>
        )}
      </Form.Item>
      <p>{intl.get('home.feedback_what_to_know')}</p>
      <Form.Item>
        {getFieldDecorator(
          'comments',
          {}
        )(
          <TextArea
            autoSize={{ minRows: 3, maxRows: 6 }}
            placeholder={intl.get('home.comments')}
          />
        )}
      </Form.Item>
    </Modal>
  );
};

export default Form.create()(React.memo(FeedbackModal));
