import React, { useState, useRef, useEffect } from 'react';
import { Button, Collapse } from 'antd';
import { connect } from 'dva';
import intl from 'react-intl-universal';
import styles from './HomeGuide.less';

import VideoModal from './VideoModal';

import { secondaryMenuData, videos } from './home_guide_data_list';
import { CommonDataCollect } from 'utils/utils'

import guide1 from 'assets/home/guide1.png';
import guide2 from 'assets/home/guide2.png';
import guide3 from 'assets/home/guide3.png';
import videoicon from 'assets/home/videoicon.png';
import blogicon from 'assets/home/blogicon.svg';
import moreIcon from 'assets/home/moreIcon.png';
import arrow_drop_up from 'assets/home/arrow_drop_up.svg'

const { Panel } = Collapse;
function HomeGuide(props) {
  
  const [currentActiveKey, setCurrentActiveKey] = useState([]);
  const [videoVisible, setVideoVisible] = useState(false);
  const [currentPlayVideoTitle, setCurrentPlayVideoTitle] = useState();
  const [currentPlayVideoName, setCurrentPlayVideoName] = useState();
  const [flag,setflag] = useState(false)

  useEffect(()=>{
    const tag = props.guideInfo.tag
    if(tag === 'tag-user-n'){
      setCurrentActiveKey(['1'])
    }else if(tag === 'tag-user-e'){
      setCurrentActiveKey(['2'])
    }else if(tag === 'tag-user-m'){
      setCurrentActiveKey(['3'])
    }else{
      setCurrentActiveKey(['1'])
    }
  },[props.guideInfo.tag])
  
  const callback = () => {
    setflag(!flag)
  };

  const toFunctionHandler = data => {
    if (data.type === 'blog') {
      CommonDataCollect({
        event_type:'home_guide_video',
        source:'secondary_menu_click_blog',
        action:`${data.name}`
      })
      window.open(data?.link);
      return;
    }
    CommonDataCollect({
			event_type:'home_guide_video',
			source:'secondary_menu_click_video',
			action:`${data.mv}`
		})
    setVideoVisible(true);
    setCurrentPlayVideoName(data?.mv);
    setCurrentPlayVideoTitle(data.title)
  };

  const changeSecondMenu = (data, index) => {
    let arr = [];
    if (currentActiveKey.includes(data.id)) {
      CommonDataCollect({
        event_type:'home_guide_video',
        source:'secondary_menu_close',
        action:`switch_${index}`
      })
      setCurrentActiveKey(currentActiveKey.filter(i => i !== data.id));
    } else {
      CommonDataCollect({
        event_type:'home_guide_video',
        source:'secondary_menu_open',
        action:`switch_${index}`
      })
      setCurrentActiveKey([data.id, ...currentActiveKey]);
    }
  };
  const afterChangeVideo = (item)=>{
    console.log(item.title);
    setCurrentPlayVideoTitle(item.title);
  }
  
  return (
    (
      <div className={styles.homeGuide}>
        <Collapse
          defaultActiveKey={['1']}
          onChange={callback}
          className={styles.Collapse}
          expandIconPosition={'right'}
          expandIcon={()=><img src={arrow_drop_up} className={styles.arrowIcon} style={{
            transform: flag
              ? 'rotate(180deg)'
              : 'rotate(0deg)'
          }} alt="" />}
        >
          <Panel
            className={styles.primaryMenu}
            key={'1'}
            header={
              <span className={styles.titleContainer}>
                <span className={styles.title}>
                  {intl.getHTML('home.newGuide.primary_title')}
                </span>
                <span className={styles.descriptions}>
                  {intl.getHTML('home.newGuide.primary_descriptions')}
                </span>
              </span>
            }
          >
            {secondaryMenuData.map((item,index) => (
              <Collapse
                activeKey={currentActiveKey}
                onChange={callback}
                className={styles.secondaryMenu}
                expandIconPosition={'right'}
                expandIcon={isActive => (
                  <span
                    onClick={() => changeSecondMenu(item,index + 1)}
                    className={styles.activeIcon}
                  >
                    {currentActiveKey.includes(item.id)
                      ? intl.getHTML('home.newGuide.Leave')
                      : intl.getHTML('home.newGuide.Expand')}
                    <img
                      src={moreIcon}
                      style={{
                        transform: currentActiveKey.includes(item.id)
                          ? 'rotate(180deg)'
                          : 'rotate(0deg)'
                      }}
                      alt=""
                    />
                  </span>
                )}
              >
                <Panel
                  className={styles.menu}
                  disabled
                  key={item.id}
                  header={
                    <div className={styles.titleContainer}>
                      <img src={item.img} className={styles.titleImg} alt="" />
                      <div className={styles.des}>
                        <span className={styles.title}>{item.title}</span>
                        <span className={styles.descriptions}>
                          {item.descriptions}
                        </span>
                      </div>
                    </div>
                  }
                >
                  <div className={styles.funcContainer}>
                    {item.chlidren.map(i => (
                      <div
                        className={styles.function}
                        >
                        <img
                          className={styles.functionIcon}
                          src={i.icon}
                          alt=""
                          />
                        <span
                          onClick={() => toFunctionHandler(i)}
                          className={
                            i.type === 'video'
                              ? styles.videoStyle
                              : styles.blogStyle
                          }
                        >
                          {i.title}
                        </span>
                      </div>
                    ))}
                  </div>
                </Panel>
              </Collapse>
            ))}
          </Panel>
        </Collapse>
        <VideoModal
          closeVideoModal={() => {
            setVideoVisible(false);
          }}
          title={currentPlayVideoTitle}
          visible={videoVisible}
          afterChangeVideo={afterChangeVideo}
          currentPlayVideoName={currentPlayVideoName}
          videoList = {videos}
        />
      </div>
    )
  );
}
export default connect(({ global }) => ({
  guideInfo: global.guideInfo,
}))(React.memo(HomeGuide));