import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { connect } from 'dva';
import intl from 'react-intl-universal';

import { Button, Progress } from 'antd';
import {sendNoviceGuidanceInfo, CommonDataCollect} from 'utils/utils'

import styles from './NoviceGuidance.less';

import closeIcon from 'assets/mobile/close_icon.png'
import to_video_order from 'assets/home/to_video_order.png'

const NoviceGuidance = ({ userInfo, dispatch, lang,noviceGuidanceInfo }) => {
  const [disModal,setDisModal] = useState(false)
  const [addProduct, setAddProduct] = useState(true); //是否完成添加商品
  const [pushProduct, setPushProduct] = useState(false); //是否完成push商品
  const [mapping, setMapping] = useState(false); //是否完成mapping
  const [order, setOrder] = useState(false); //是否完成下單
  const [percent, setPercent] = useState(0); //新手引導完成百分比

  const steps_data_list = [
    {
      id: 1,
      title: intl.getHTML('home.guidance.add_product_title'),
      describe:intl.getHTML('home.guidance.add_product_desc'),
      btn_text: addProduct ? intl.getHTML('home.guidance.btn_complete') : intl.getHTML('home.guidance.add_product'),
      disabled: addProduct
    },
    {
      id: 2,
      title: intl.getHTML('home.guidance.push_to_shopify_title'),
      describe:intl.getHTML('home.guidance.push_to_shopify_desc'),
      btn_text: pushProduct ? intl.getHTML('home.guidance.btn_complete') : intl.getHTML('home.guidance.push_to_shopify'),
      disabled: pushProduct
    },
    {
      id: 3,
      title: intl.getHTML('home.guidance.suppiler_mapping_title'),
      describe:intl.getHTML('home.guidance.suppiler_mapping_desc'),
      btn_text: mapping ? intl.getHTML('home.guidance.btn_complete') : intl.getHTML('home.guidance.suppiler_mapping'),
      disabled: mapping
    },
    {
      id: 4,
      title: intl.getHTML('home.guidance.place_order_title'),
      describe:intl.getHTML('home.guidance.place_order_desc'),
      btn_text: order ? intl.getHTML('home.guidance.btn_complete') : intl.getHTML('home.guidance.place_order'),
      disabled: order
    }
  ];

  const percent_steps = {
    0: 0,
    1: 25,
    2: 50,
    3: 75,
    4: 100
  };


  useEffect(() => {
    console.log(noviceGuidanceInfo,'noviceGuidanceInfo...');
    setAddProduct(Boolean(noviceGuidanceInfo?.imported))
    setPushProduct(Boolean(noviceGuidanceInfo?.pushed))
    setMapping(Boolean(noviceGuidanceInfo?.mapped))
    setOrder(Boolean(noviceGuidanceInfo?.placed))
    setDisModal(!Boolean(noviceGuidanceInfo?.display))
    const precents = steps_data_list.filter(i => {
      if (i.disabled) {
        return i;
      }
    }).length;
    setPercent(percent_steps[precents]);
  }, [steps_data_list,disModal]);

  const closeGuidanceHandler = () => {
    sendNoviceGuidanceInfo({display : 1},()=>{
      dispatch({ type:'user/getNoviceGuidance'})
    }).then((res)=>{
      setDisModal(false)
    })
  }

  // 點擊按鈕切換路由
  const btnClickHandler = id => {
    return () => {
      if (id == 1) {
        window.location.href = '/app/find_suppliers';
      } else if (id == 2) {
        window.location.href = '/app/storemanage/import';
      } else if (id == 3) {
        window.location.href = '/app/storemanage/myproduct';
      } else if (id == 4) {
        window.location.href = '/app/order/list';
      }
    };
  };

  const videoClickHandler = ()=>{
    CommonDataCollect({
      event_type:'home_guide_order_video',
      action:'home_click_to_order_video'
    },()=>{
      window.open('/app/tutorials_video?id=2');
    })
  }

  return (
    <>
      {disModal ? IS_MOBILE ? (
        <div className={styles.mcontainer}>
          <div className={styles.title_con}>
            <div className={styles.title}>
              <p className={styles.title_tit}>{intl.getHTML('home.guidance.title')}</p>
              <p className={styles.title_des}>
                {intl.getHTML('home.guidance.desc')}
              </p>
            </div>
            <div className={styles.steps}>
              {percent == '100' ? 
              <img className={styles.img} onClick={closeGuidanceHandler} src={closeIcon} alt="" />
              :
              null}
            </div>
          </div>
          <div className={styles.steps_con}>
            {steps_data_list.map(item => {
              return (
                <div className={styles.steps}>
                  <div className={styles.left}>
                    <div
                      className={
                        !item.disabled ? styles.status_success : styles.status
                      }
                    >
                      {intl.getHTML('home.guidance.steps')} {item.id}
                    </div>
                    <div className={styles.title}>{item.title}</div>
                    <div className={styles.des}>{item.describe}</div>
                  </div>
                  <div className={styles.right}>
                    <Button
                      disabled={item.disabled}
                      onClick={btnClickHandler(item.id)}
                      className={styles.btn}
                    >
                      {item.btn_text}
                    </Button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div className={styles.container}>
          <div className={styles.title_con}>
            <div className={styles.title}>
              <p className={styles.title_tit}>{intl.getHTML('home.guidance.title')}</p>
              <p className={styles.title_des}>
                {intl.getHTML('home.guidance.desc')}
              </p>
            </div>
            <div className={styles.steps}>
              {percent == '100' ? 
              <img className={styles.img} onClick={closeGuidanceHandler} src={closeIcon} alt="" />
              :
              <Progress
                type="circle"
                strokeColor="#4CAF50"
                trailColor="#9FA8DA"
                strokeLinecap="square"
                width={30}
                strokeWidth={15}
                showInfo={false}
                percent={percent}
                steps={4}
              />}
            </div>
          </div>
          <div className={styles.steps_con}>
            {steps_data_list.map(item => {
              return (
                <div className={styles.steps}>
                  <div className={styles.left}>
                    <div
                      className={
                        !item.disabled ? styles.status_success : styles.status
                      }
                    >
                      {intl.getHTML('home.guidance.steps')} {item.id}
                    </div>
                    <div className={styles.title}>{item.title}</div>
                    <div className={styles.des}>{item.describe}</div>
                  </div>
                  <div className={styles.right}>
                    <Button
                      disabled={item.disabled}
                      onClick={btnClickHandler(item.id)}
                      className={styles.btn}
                    >
                      {item.btn_text}
                    </Button>
                    {item.id === 4 ? <span className={styles.to_video_order}>
                      <img src={to_video_order} alt="" />
                      <a onClick={videoClickHandler} target='_blank'>VIEW VIDEO</a>
                    </span> :null}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default connect(({ login, global,user }) => ({
  userInfo: login.userInfo,
  lang: global.lang,
  noviceGuidanceInfo:user?.noviceGuidanceInfo
}))(React.memo(NoviceGuidance));
