import React from 'react';
import { Card } from 'antd';
import styles from './HomeCard.less';
import CardMoreAction from './CardMoreAction';
import ImageCard from './ImageCard';
import NormalCard from './NormalCard';
import NotificationCard from './NotificationCard';

const Cards = {
  default: NormalCard,
  imgCard: ImageCard,
  notificationCard: NotificationCard
};

const homeCardStyle = {
  // width: 724
  borderRadius: '12px'
};

const homeCardBodyStyle = {
  // padding: 0
};

const HomeCard = ({
  cardType,
  cardInfo,
  showMoreAction = true,
  onSelectAction
}) => {
  const CardContent = Cards[cardType] || Cards.default;
  const actionHanlder = e => {
    if (typeof onSelectAction === 'function') {
      onSelectAction(e, cardInfo);
    }
  };

  return (
    <Card
      size="small"
      title={null}
      style={homeCardStyle}
      bodyStyle={homeCardBodyStyle}
    >
      <div className={styles.cardContentLeft}>
        <CardContent cardInfo={cardInfo} />
      </div>
      <div className={styles.cardContentRight}>
        {showMoreAction ? (
          <CardMoreAction onSelectAction={actionHanlder} />
        ) : null}
      </div>
    </Card>
  );
};

export default React.memo(HomeCard);
